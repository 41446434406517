.add-product-form .sku-table {
  width: 100%;
  overflow-x: auto;
  margin: 0.5rem 0 1rem 0;
}
.add-product-form .sku-table table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #d7d9da;
  border-radius: 4px;
}
.add-product-form .sku-table table th {
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid #d7d9da;
  color: var(--black-color);
  font-size: 14px;
}
.add-product-form .sku-table table td {
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid #d7d9da;
  color: var(--black-color);
  font-size: 14px;
}

.add-product-form .Add-sku {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 6px;
}
.add-product-form .add-button {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  border: 1px solid lightgray;
  padding: 2px 6px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}
.add-product-form .Add-sku p {
  font-size: 13.5px;
}

.upload-container {
  width: 100%;
  height: 10rem; /* equivalent to 40 (tailwind 'h-40') */
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border: 2px dashed gray;
  border-radius: 0.5rem; /* equivalent to 'rounded-lg' */
  cursor: pointer;
  color: #6b7280; /* tailwind 'text-gray-500' */
  transition: background-color 0.2s ease-in-out;
}

.upload-container:hover {
  background-color: #f9fafb; /* tailwind 'hover:bg-gray-50' */
}
.upload-container .fileUpload-image-div {
  height: 50px;
  width: 50px;
}
.upload-container .fileUpload-image-div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.upload-container .uploaded-image-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.cloud-icon {
  font-size: 2.5rem; /* equivalent to 'text-4xl' */
  margin-bottom: 0.5rem;
}

.upload-text {
  font-size: 1rem; /* default paragraph text size */
}

.file-size-text {
  font-size: 0.75rem; /* equivalent to 'text-xs' */
  color: #9ca3af; /* tailwind 'text-gray-400' */
}

.add-product .ant-form-item {
  margin-bottom: 12px;
}
