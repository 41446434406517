@import "../src/styles/variables.css";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-1huaqfz-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #009a00 !important;
}

.css-1huaqfz-MuiInputBase-root-MuiInput-root::after {
  border-bottom: 2px solid black !important;
  font-family: var(--font-family) !important;
}
.css-1huaqfz-MuiInputBase-root-MuiInput-root::after:focus {
  border-bottom: 2px solid black !important;
}

.css-1sh6wjw-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: black !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #009a00 !important;
}
.css-kg5swy > span {
  padding-right: 0px !important;
}
.css-kg5swy {
  max-width: 50% !important;
}

.css-1o0kqxp-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: var(--font-family) !important;
}
.css-8voplp-MuiFormLabel-root-MuiInputLabel-root {
  font-family: var(--font-family) !important;
}
.ant-form legend {
  display: block;
  width: auto;
  margin-bottom: 24px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #009a00;
  border-color: #009a00;
  color: #009a00 !important;
}

.ant-layout .ant-layout-sider {
  background: #fff !important;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: white !important;
}
.ant-btn-default:hover {
  color: #009a00 !important;
  border-color: #009a00 !important;
  background-color: white !important;
}
.ant-select-selector:hover {
  border-color: #009a00 !important;
  background-color: white !important;
}

.ant-checkbox .ant-checkbox-input  {
  color: #009a00 !important;
  border-color: #009a00 !important;
  position: absolute;
  inset: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  margin: 0;
}
.ant-checkbox-inner{
  color:#009a00 !important;
}

/* Add product Form */
.add-product-form .ant-select-selection-placeholder,
.add-product-form .ant-select-selection-item {
  flex: none !important;
}
.ant-select-selector {
  border: none ;
  color: white ;
  background: none !important;
  font: bold !important;
}

.ant-select-selector:hover {
  border: none ;
  color: black;
  background: none !important;
  font: 600 !important;
}
.order-management .ant-select-selection-item{
  color: white !important;
  font-weight: bold !important;
}